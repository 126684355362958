import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Unexpected = lazy(() => import('.'));

export enum UnexpectedRoutesEnum {
  UNEXPECTED = '/unexpected',
}

export const unexpectedScreen: RouteObject[] = [
  {
    path: UnexpectedRoutesEnum.UNEXPECTED,
    element: <Unexpected />,
  },
];
