import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Home = lazy(() => import('.'));

export enum HomeRoutesEnum {
  Home = '/',
}

export const homeScreens: RouteObject[] = [
  {
    path: HomeRoutesEnum.Home,
    element: <Home />,
  },
];
