import { init } from '@noriginmedia/norigin-spatial-navigation';
import { Router as RemixRouter } from '@remix-run/router';
import { Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { homeScreens } from './modules/home/routes';
import { movieScreens } from './modules/movie/routes';
import { searchScreens } from './modules/search/routes';
import { unexpectedScreen } from './modules/unexpected/routes';
import { videoScreens } from './modules/videoPlayer/routes';
import { ReactQueryProvider } from './QueryClientProvider';
import { useWheelNavigation } from './shared/hooks/useWheelNavigation';

const routes: RouteObject[] = [
  ...homeScreens,
  ...searchScreens,
  ...unexpectedScreen,
  ...movieScreens,
  ...videoScreens,
];

const router: RemixRouter = createBrowserRouter([...routes]);

init();

const App = () => {
  const { handleNavigation } = useWheelNavigation();

  useEffect(() => {
    document.addEventListener('wheel', handleNavigation);

    return () => {
      document.removeEventListener('wheel', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <ReactQueryProvider>
      <Suspense fallback={<></>}>
        <RouterProvider router={router} />
      </Suspense>
    </ReactQueryProvider>
  );
};

export default App;
