import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const MovieDetails = lazy(() => import('.'));

enum MovieRoutesEnum {
  MOVIE = '/movie/:id',
}

export const movieScreens: RouteObject[] = [
  {
    path: MovieRoutesEnum.MOVIE,
    element: <MovieDetails />,
  },
];
