import { navigateByDirection } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback } from 'react';

export const useWheelNavigation = () => {
  const handleNavigation = useCallback((event: WheelEvent): void => {
    const directionMapping = {
      [event.deltaY > 0 ? 'down' : 'up']: event.deltaY,
      [event.deltaX > 0 ? 'right' : 'left']: event.deltaX,
    };

    for (const direction in directionMapping) {
      if (directionMapping[direction] !== 0) {
        navigateByDirection(direction, event);
      }
    }
  }, []);

  return {
    handleNavigation,
  };
};
