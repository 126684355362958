import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const VideoPlayer = lazy(() => import('.'));

enum VideoRoutesEnum {
  VIDEO = '/video/:id',
}

export const videoScreens: RouteObject[] = [
  {
    path: VideoRoutesEnum.VIDEO,
    element: <VideoPlayer />,
  },
];
