import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Search = lazy(() => import('.'));

export enum SearchRoutesEnum {
  SEARCH = '/search',
}

export const searchScreens: RouteObject[] = [
  {
    path: SearchRoutesEnum.SEARCH,
    element: <Search />,
  },
];
